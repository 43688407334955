<template>
  <!-- Confirm Modal -->
  <modal v-loading="loader" :show.sync="$store.state.modals.confirmModalVisibility">
    <template slot="header">
      <h5 class="modal-title">Are you sure?</h5>
    </template>
    <div class="content mt--4 ml--1">
      You want to continue with this action.
    </div>
    <template slot="footer">
      <base-button type="secondary" class="btn-gray" @click="$store.commit('hideConfirmModal')">Cancel</base-button>
      <base-button class="btn-primary-action br-4" @click="onConfirm">Confirm</base-button>
    </template>
  </modal>
  <!-- Delete Modal Finished -->
</template>
<script>
export default {
  props: ['loader', 'module', 'confirmId'],
  methods: {
    onConfirm() {
      this.$emit('onConfirm', this.confirmId);
    }
  },
};
</script>

<style scoped>

</style>
