export default {
  data(){
    return {
      copyToolTip: "Copy to clipboard.",
      copySvg: `<svg xmlns="http://www.w3.org/2000/svg" width="14.16" height="14.16" viewBox="0 0 14.16 14.16">
      <g id="noun_copy_1451434" transform="translate(0.5 0.5)">
        <g id="Group" transform="translate(0 0)">
          <path id="Compound_Path" data-name="Compound Path" d="M44.575,17.9H34.9v9.675h9.675Zm-.41,9.265H35.31V18.31h8.855Z" transform="translate(-31.415 -17.9)" stroke="currentColor" stroke-width="1"/>
          <path id="Path" d="M27.575,43.059h-.41v1.107H18.31V35.31h1.066V34.9H17.9v9.675h9.675Z" transform="translate(-17.9 -31.415)" stroke="currentColor" stroke-width="1"/>
        </g>
      </g>
    </svg>`
    }
  },
  methods: {
    copyToClipboard(value = '') {
      const textArea = document.createElement("textarea");
      textArea.value = value;
      document.body.appendChild(textArea);
      textArea.select();
      document.execCommand("copy");
      document.body.removeChild(textArea);
      this.copyToolTip = 'Copied.';
  },
  resetToolTip(){
    this.copyToolTip = 'Copy to clipboard.'
}
  }
}
