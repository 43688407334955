<template>
  <!-- Delete Modal -->
    <modal :show.sync="$store.state.modals.removeModalVisibility">
    <template slot="header">
        <h5 class="modal-title">Are you sure?</h5>
    </template>
    <div slot="" v-loading="loader" class="mt--4 ml--1">
      <span>{{modalText}}</span>
    </div>
    <template slot="footer">
      <h4 v-if="warningText" class="warning-font">Warning: {{ warningText }}</h4>
      <base-button type="secondary" class="btn-gray rounded-btn"
                     @click="$store.commit('hideRemoveModal')">{{ deferText }}</base-button>
        <base-button type="danger" class="rounded-btn" :disabled="loader"
                     @click="onRemove">{{ successText }}</base-button>
    </template>
  </modal>
  <!-- Delete Modal Finished -->
</template>
<script>
export default {
  props: ['loader', 'modalText', 'removeId', 'warningText', 'identityData','buttonText'],
  computed:{
    successText(){
      if (this.buttonText){
        return this.buttonText.success
      }
      return 'Remove'
    },
    deferText(){
      if (this.buttonText){
        return this.buttonText.defer
      }
      return 'Cancel'
    }
  },
  methods:{
    onRemove() {
      if(Array.isArray(this.removeId) === false) {
        this.$emit('onRemove', this.removeId, this.identityData ?? null);
      } else {
        this.$emit('onRemoveMultiple', this.removeId, this.identityData ?? null);
      }
    }
  },
};
</script>

<style scoped>

.warning-font{
  color: rgba(220, 38, 38, 100)
}

.btn-gray{
  color: white;
  border: 1px solid #8898AA;
  background: #8898AA 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 8px #32325D26;
}

.rounded-btn{
  border-radius: 6px;
}

</style>
