<template>
  <div class="m-0 px-3 pt-4 pb-0 pr-2 discount-ad-show" v-loading="loaders.fetching">
    <back-button class="ml-4" />
    <div class="ml-2 d-flex justify-content-between">
      <div>
        <h1>Discount Ads Info</h1>
      </div>
      <div>
        <base-button type="dark-blue" :disabled="discountAd ? true : false"
          @click="$router.push({ name: 'admin.discount_ads.edit', })">Add Discount Ad</base-button>
      </div>
    </div>
    <discount-ad v-if="discountAd" :discount-ad="discountAd" @deleteDiscountAd="deleteDiscountAd(discountAd)"
      @resetStats="resetStats(discountAd)" @toggleStatus="toggleStatus(discountAd)" :patientTypes="patientTypes" />
    <div v-else class="mt-4 cardStyle text-center">
      <p class="m-5 font-weight-bold">
        No Discount Ad Found!
      </p>
    </div>
    <div class="modals">
      <remove-modal :loader="loaders.removeModal" :modal-text="remove.modalText" @onRemove="removeDiscountAd" />
      <confirm-action :loader="loaders.confirmModal" confirm-id="-1" @onConfirm="confirmedReset"></confirm-action>
    </div>
  </div>
</template>
<script>
import BackButton from "@/components/Router/BackButton";
import DiscountAd from "@/views/Components/SuperAdmin/DiscountAds/Card";
import RemoveModal from "@/components/Modals/RemoveModal.vue";
import ConfirmAction from "@/components/Modals/ConfirmAction";
import patientType from "@/mixins/patientTypeMixin";

export default {
  components: {
    BackButton,
    DiscountAd,
    RemoveModal,
    ConfirmAction
  },
  mixins: [patientType],
  data() {
    return {
      loaders: {
        fetching: false,
        removeModal: false,
        confirmModal: false,
      },
      reset: {
        index: -1,
        id: ''
      },
      remove: {
        modalText: '',
      },
      discountAd: null,
    };
  },
  mounted() {
    this.getDiscountAd();
  },
  methods: {
    async getDiscountAd() {
      this.loaders.fetching = true;
      try {
        let response = await this.$store.dispatch('AdsModule/_getDiscountAd');

        let {
          data: { data },
        } = response;
        this.discountAd = data;
      } catch (error) {

      }
      this.loaders.fetching = false;
    },

    deleteDiscountAd(item) {
      this.$store.commit("showRemoveModal");
      this.remove.modalText = 'You want to remove this discount ad.';
    },
    async removeDiscountAd() {
      this.loaders.removeModal = true;
      try {
        let response = await this.$store.dispatch('AdsModule/_deleteDiscountAd');
        let {
          data: { message },
        } = response;
        this.$notify.success({
          title: 'Discount Ad',
          dangerouslyUseHTMLString: true,
          message: message
        });
        this.discountAd = null;
        this.$store.commit("hideRemoveModal");
      } catch (error) {
        let message = error?.response?.data?.message || "Error";
        this.$notify.error({
          title: 'Discount Ad',
          dangerouslyUseHTMLString: true,
          message: message
        });
      }
      this.loaders.removeModal = false;
    },
    resetStats(data, index) {
      this.$store.commit("showConfirmModal");
      this.reset.index = index;
      this.reset.id = data.id;
    },
    async confirmedReset() {
      this.loaders.confirmModal = true;
      try {
        let response = await this.$store.dispatch('AdsModule/_resetStatsDiscountAd');

        let {
          data: { message, data },
        } = response;
        this.$notify.success({
          title: 'Discount Ad',
          dangerouslyUseHTMLString: true,
          message: message
        });
        this.discountAd = data;
        this.$store.commit("hideConfirmModal");

      } catch (error) {
        let message = error?.response?.data?.message || "Error";
        this.$notify.error({
          title: 'Discount Ad',
          dangerouslyUseHTMLString: true,
          message: message
        });
      }
      this.loaders.confirmModal = false;
    },
    async toggleStatus() {
      this.loaders.confirmModal = true;
      try {
        let response = await this.$store.dispatch('AdsModule/_toggleStatusDiscountAd');

        let {
          data: { message, data },
        } = response;
        this.$notify.success({
          title: 'Discount Ad',
          dangerouslyUseHTMLString: true,
          message: message
        });
        this.discountAd = data;
        this.$store.commit("hideConfirmModal");

      } catch (error) {
        let message = error?.response?.data?.message || "Error";
        this.$notify.error({
          title: 'Discount Ad',
          dangerouslyUseHTMLString: true,
          message: message
        });
      }
      this.loaders.confirmModal = false;
    },

  },
};
</script>
<style scoped lang="scss"></style>
